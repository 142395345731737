body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.lapBox {
  border-radius: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  display: inline-block;
  z-index: 1000;
}

.lapBox1, .lapBox2, .lapBox3, .lapBox4 {
  position:relative;
  transform: scale(1.3);
  transition: transform .5s ease;
  background-color: #f9a1a1;
  z-index: 1000;
}



.runnerName {
  display: block;
 color: rgb(7, 7, 177);
  font-size: .90em;
}

.lapNumber {
  display: block;
  font-weight: bold;
  color: #777777;
  font-size: .4em;
}

.lapTime {
  display: block; 
  font-size: 1.5em;
}

.stopped {
  display: block;
  font-weight: bold;
  font-size: 1.2em;
}

.previousSplits {
  font-size: .5em;
  color: grey;
}

.lapList {
  display: block;
  font-size: 0.5em;
}

.runningTime {
  display: flex;
  color: blue;
  align-items: left;
  margin-left: 28%;
}

.fourRunners {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;
  grid-row-gap: 15px;
  

}

.nameGroup {
  display: flex;
  flex-direction: column;
}

.nameGroup > * {
  margin: 5px;
}

.redRow {
  background-color: #ffcccc;
  padding: 5px;
}

